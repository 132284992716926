import React, { useEffect, useState } from 'react';
import { Grid } from "@mui/material";
import { parseDate } from "./utils";
import { acctMonthToLabel } from "./hooks";
import { CommonButton } from "../common/commonButton";
import { numberFormatter } from "../../utils/generalFunctions";

const Container = {
  flexGrow: 1,
  justifyContent: "flex-end",
  alignItems: "flex-end",
  display: "flex",
};

const AllocationDetails = ({
  policy,
  handleClose,
  handleSave,
  transaction,
  currentRowMode,
  associatedActivities,
  apiProgressBar,
  setApiProgressBar,
}) => {

  return (
    <div className="policy-details">
      <Grid container xs={12} spacing={2}>
        <Grid item md={2.4} paddingLeft={2}>
          <Grid marginBottom={1}>
            <Grid item color={"#555"} fontWeight={"medium"}>
              Policy Number
            </Grid>
            <Grid item fontWeight={"bold"}>
              {policy?.policy_id || ""}
            </Grid>
          </Grid>
          <Grid marginBottom={1}>
            <Grid item color={"#555"} fontWeight={"medium"}>
              UMR
            </Grid>
            <Grid item fontWeight={"bold"}>
              {policy?.allocation_umr || ""}
            </Grid>
          </Grid>
          <Grid marginBottom={1}>
            <Grid item color={"#555"} fontWeight={"medium"}>
              Master Binder
            </Grid>
            <Grid item fontWeight={"bold"}>
              {policy?.allocation_binder || ""}
            </Grid>
          </Grid>
          <Grid marginBottom={1}>
            <Grid item color={"#555"} fontWeight={"medium"}>
              Binding Agreement
            </Grid>
            <Grid item fontWeight={"bold"}>
              {policy?.binding_agreement || ""}
            </Grid>
          </Grid>
          <Grid marginBottom={1}>
            <Grid item color={"#555"} fontWeight={"medium"}>
              Producing Entity Name
            </Grid>
            <Grid item fontWeight={"bold"}>
              {policy?.policy_fk?.Producing_Entity || ""}
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={2.4} paddingLeft={4}>
          <Grid marginBottom={1}>
            <Grid item color={"#555"} fontWeight={"medium"}>
              SCM Partner Name
            </Grid>
            <Grid item fontWeight={"bold"}>
              {policy?.allocation_scm === "nan"
                ? ""
                : policy?.allocation_scm || ""}
            </Grid>
          </Grid>
          <Grid marginBottom={1}>
            <Grid item color={"#555"} fontWeight={"medium"}>
              EEA/Non EEA
            </Grid>
            <Grid item fontWeight={"bold"}>
              {policy?.allocation_eea?.toUpperCase() ?? (
                (policy?.policy_fk?.Producing_Entity === "MEEA" ||
                  policy?.policy_fk?.Producing_Entity === "MEU2")
                  ? "EEA"
                  : ""
              )}
            </Grid>
          </Grid>
          <Grid marginBottom={1}>
            <Grid item color={"#555"} fontWeight={"medium"}>
              Accounting Month
            </Grid>
            <Grid item fontWeight={"bold"}>
              {policy?.accounting_monthyear
                ? acctMonthToLabel(policy?.accounting_monthyear)
                : ""}
            </Grid>
          </Grid>
          <Grid marginBottom={1}>
            <Grid item color={"#555"} fontWeight={"medium"}>
              Associated Activities
            </Grid>
            {associatedActivities && Object.keys(associatedActivities).length > 0 && (
              <Grid item fontWeight={"bold"}>
                {Object.keys(associatedActivities).join(", ")}
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item md={2.4} paddingLeft={4}>
          <Grid>
            <Grid item color={"#555"} fontWeight={"medium"}>
              Allocated Date
            </Grid>
            <Grid item fontWeight={"bold"}>
              {policy?.allocation_date
                ? parseDate(new Date(policy?.allocation_date))
                : ""}
            </Grid>
          </Grid>
          <Grid marginBottom={1} marginTop={1}>
            <Grid item color={"#555"} fontWeight={"medium"}>
              Invoice Verification
            </Grid>
            <Grid item fontWeight={"bold"}>
              {policy?.allocation_invoice_verification || ""}
            </Grid>
          </Grid>
          <Grid marginBottom={1}>
            <Grid item color={"#555"} fontWeight={"medium"}>
              XFI Batch Number
            </Grid>
            <Grid item fontWeight={"bold"}>
              {policy?.XFIbatchno || ""}
            </Grid>
          </Grid>
          <Grid>
            <Grid item color={"#555"} fontWeight={"medium"}>
              GXB Batch Number
            </Grid>
            <Grid item fontWeight={"bold"}>
              {policy?.GXPbatchno || ""}
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={2.4} paddingLeft={4}>
          <Grid width={"200px"}>
            <Grid marginBottom={1}>
              <Grid item color={"#555"} fontWeight={"medium"}>
                Instalment Number
              </Grid>
              <Grid item fontWeight={"bold"}>
                {policy?.policy_installment_number || ""}
              </Grid>
            </Grid>
            <Grid marginBottom={1} marginTop={1}>
              <Grid item color={"#555"} fontWeight={"medium"}>
                Instalment Due Date
              </Grid>
              <Grid item fontWeight={"bold"}>
                {policy?.policy_fk?.Installment_Due_date || ""}
              </Grid>
            </Grid>
            <Grid item marginTop={1} color={"#555"} fontWeight={"medium"}>
              Instalment Agency Amount
            </Grid>
            <Grid item fontWeight={"bold"} textAlign={"end"}>
              {numberFormatter(
                policy?.policy_fk?.Installment_Agency_Amount_in_Orig ?? 0
              )}
            </Grid>
            <Grid item marginTop={1} color={"#555"} fontWeight={"medium"}>
              Settlement Currency
            </Grid>
            <Grid item fontWeight={"bold"}>
              {policy?.settlement_currency ??
                policy?.policy_fk?.Settlement_Ccy ??
                ""}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          md={2.4}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "end",
          }}
        >
          <Grid width={"240px"}>
            <Grid item color={"#555"} fontWeight={"medium"}>
              Original Amount
            </Grid>
            <Grid item fontWeight={"bold"} textAlign={"end"}>
              {numberFormatter(policy?.policy_fk?.Installment_Agency_Amount_in_Orig ?? 0)}
            </Grid>
            <Grid item color={"#555"} marginTop={1} fontWeight={"medium"}>
              XFI Variance Amount
            </Grid>
            <Grid item fontWeight={"bold"} textAlign={"end"}>
              {numberFormatter(policy?.XFI_Variance_Amount ?? 0)}
            </Grid>
            <Grid item color={"#555"} marginTop={1} fontWeight={"medium"}>
              GXB Amount
            </Grid>
            <Grid item fontWeight={"bold"} textAlign={"end"}>
              {numberFormatter(policy?.GXBAmount ?? 0)}
            </Grid>
            <Grid item color={"#555"} marginTop={1} fontWeight={"medium"}>
              USD Receivable Amount
            </Grid>
            <Grid item fontWeight={"bold"} textAlign={"end"}>
              {numberFormatter(
                policy?.cash_tracker_data?.Receivable_Amount_calculated || 0
              )}
            </Grid>
            <Grid item marginTop={1} color={"#555"} fontWeight={"medium"}>
              USD Allocated Amount
            </Grid>
            <Grid item fontWeight={"bold"} textAlign={"end"}>
              {numberFormatter(
                policy?.cash_tracker_data?.Allocated_Amount_calculated || 0
              )}
            </Grid>
            <Grid item color={"#555"} marginTop={1} fontWeight={"medium"}>
              USD Remaining Balance Amount
            </Grid>
            <Grid item fontWeight={"bold"} textAlign={"end"}>
              {numberFormatter(
                policy?.cash_tracker_data?.Remaining_Balance_usd || 0
              )}
            </Grid>
            <Grid sx={Container} mt={2} mb={3}>
              <CommonButton
                text="Cancel"
                handleClick={() => handleClose("")}
                sx={{ backgroundColor: "#ffff", color: "#000000" }}
                variant="outlined"
              />
              {currentRowMode === "EDIT" && (
                <CommonButton
                  text="Save"
                  handleClick={handleSave}
                  sx={{ borderRadius: 100, marginLeft: "20px" }}
                  hoverColor="#FF5A01"
                  disabled={apiProgressBar}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default AllocationDetails;
