import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";

//inline styles
const InlineStyles = {
  TableHeader: {
    backgroundColor: "#262671",
    color: "white",
    position: "sticky",
    top: 0,
    zIndex: 1,
    height: "50px",
  },
  TableCells: {
    color: "black",
    fontSize: "12px",
    height: "44px",
    padding: "0px",
  },
  TableBodyRows: {
    border: "1px solid #ddd",
    marginTop: "10px",
    paddingTop: "15px",
    verticalAlign: "top",
  },
  TableBodyRowCells: {
    height: "20px",
    padding: "0px",
  },
  PaymentReceiveDate: {
    width: "100px",
    height: "33px",
    padding: "0px",
  },
  BrokerBranchTableCell: {
    height: "20px",
    padding: "3px",
  },
  ReceivableAmountTableCell: {
    height: "20px",
    padding: "3px",
  },
  PaymentCurrencyCodeTableCell: {
    height: "20px",
    padding: "3px",
  },
  BankExchangeRateTableCell: {
    height: "20px",
    padding: "3px",
  },
  BankExchangeChargesTableCell: {
    height: "20px",
    padding: "3px",
  },
  entity_divisionsTableCell: {
    height: "20px",
    padding: "3px",
  },
  entity_divisions: {
    position: "relative",
    width: "123px",
    height: "32",
  },
  MoreVertIconStyle: {
    color: "FF5A01",
    background: "transparent",
    cursor: "pointer",
    transform: "rotate(90deg)",
  },
  BankChargesTableCell: {
    height: "20px",
    padding: "3px",
  },
};
export const AddBrokerDetails = ({
  selectAll,
  handleSelectAll,
  handleInputChange,
  handleCheckboxChange,
  rows,
  viewMode,
  validationStatus,
  handleentity_divisionsOpenDialog,
  selectedRows,
}) => {
  return (
    <Table>
      <TableHead style={InlineStyles.TableHeader}>
        <TableRow style={{ backgroundColor: "#FFC000" }}>
          <TableCell style={InlineStyles.TableCells}>
            <Checkbox
              checked={selectAll}
              onChange={handleSelectAll}
              style={{ color: "black" }}
            />
          </TableCell>
          <TableCell className="TableCell">Broker Master</TableCell>
          <TableCell className="TableCell">Broker</TableCell>
          <TableCell className="TableCell">Broker Branch</TableCell>
          <TableCell className="TableCell">Duplicate Count</TableCell>
          <TableCell className="TableCell">SOA Received From Broker</TableCell>
          <TableCell className="TableCell">Broker Representative</TableCell>
          <TableCell className="TableCell">Email</TableCell>
          <TableCell className="TableCell">Phone Number</TableCell>
          <TableCell className="TableCell">Broker Branch Location</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row, index) => (
          <TableRow key={index} style={InlineStyles.TableBodyRows}>
            <TableCell sx={InlineStyles.TableBodyRowCells}>
              <Checkbox
                checked={selectedRows[index] || false}
                onChange={(e) => handleCheckboxChange(index, e.target.checked)}
              />
            </TableCell>

            <TableCell sx={InlineStyles.binding_aggrement_type}>
              <TextField
                value={row.broker_name}
                onChange={(e) =>
                  handleInputChange(index, "broker_name", e.target.value)
                }
                sx={InlineStyles.entity_divisions}
                size="small"
                placeholder="Enter Details Here"
                disabled={viewMode}
              />
              <br />
              {validationStatus[index]?.broker_name && (
                <span style={{ color: "red" }}>
                  {validationStatus[index]?.broker_name}
                </span>
              )}
            </TableCell>
            <TableCell sx={InlineStyles.binding_aggrement_type}>
              <TextField
                value={row.broker}
                onChange={(e) =>
                  handleInputChange(index, "broker", e.target.value)
                }
                sx={InlineStyles.entity_divisions}
                size="small"
                placeholder="Enter Details Here"
                disabled={viewMode}
              />
              <br />
              {validationStatus[index]?.broker && (
                <span style={{ color: "red" }}>
                  {validationStatus[index]?.broker}
                </span>
              )}
            </TableCell>
            <TableCell sx={InlineStyles.binding_aggrement_type}>
              <TextField
                value={row.branch}
                onChange={(e) =>
                  handleInputChange(index, "branch", e.target.value)
                }
                sx={InlineStyles.entity_divisions}
                size="small"
                placeholder="Enter Details Here"
                disabled={viewMode}
              />
              <br />
              {validationStatus[index]?.branch && (
                <span style={{ color: "red" }}>
                  {validationStatus[index]?.branch}
                </span>
              )}
            </TableCell>
            <TableCell sx={InlineStyles.binding_aggrement_type}>
              <TextField
                value={row.duplicate_count}
                onChange={(e) =>
                  handleInputChange(index, "duplicate_count", e.target.value)
                }
                sx={{ width: "100px" }}
                size="small"
                type="number" // Set the input type to "number"
                InputProps={{
                  inputProps: {
                    min: 0, // Set minimum value to 0 if needed
                    onInput: (e) => {
                      e.preventDefault();
                      const value = e.target.value;
                      const regex = /^\d*\.?\d*$/; // Regular expression for numeric input
                      if (regex.test(value) || value === "") {
                        handleInputChange(index, "duplicate_count", value);
                      }
                    },
                  },
                }}
                disabled={viewMode}
              /><br />
              {validationStatus[index]?.duplicate_count && (
                <span style={{ color: "red" }}>
                  {validationStatus[index]?.duplicate_count}
                </span>
              )}
            </TableCell>
            <TableCell sx={InlineStyles.binding_aggrement_type}>
              <TextField
                value={row.soa_received_from_broker}
                onChange={(e) =>
                  handleInputChange(
                    index,
                    "soa_received_from_broker",
                    e.target.value
                  )
                }
                sx={InlineStyles.entity_divisions}
                size="small"
                placeholder="Enter Details Here"
                disabled={viewMode}
              />
              <br />
              {validationStatus[index]?.soa_received_from_broker && (
                <span style={{ color: "red" }}>
                  {validationStatus[index]?.soa_received_from_broker}
                </span>
              )}
            </TableCell>
            <TableCell sx={InlineStyles.binding_aggrement_type}>
              <TextField
                value={row.name}
                onChange={(e) =>
                  handleInputChange(index, "name", e.target.value)
                }
                sx={InlineStyles.entity_divisions}
                size="small"
                placeholder="Enter Details Here"
                disabled={viewMode}
              />
              <br />
              {validationStatus[index]?.name && (
                <span style={{ color: "red" }}>
                  {validationStatus[index]?.name}
                </span>
              )}
            </TableCell>
            <TableCell sx={InlineStyles.binding_aggrement_type}>
            <TextField
    value={row.email}
    onChange={(e) => {
        const inputValue = e.target.value;
        handleInputChange(index, "email", inputValue);
        if (inputValue.trim() !== "") {
            // If input is not empty, validate it as email
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailPattern.test(inputValue)) {
                // If input doesn't match email pattern, throw error
                // You can handle the error here as needed
            }
        }
    }}
    sx={InlineStyles.entity_divisions}
    size="small"
    placeholder="Enter Email Here"
    type="email"
    disabled={viewMode}
/>
              <br />
              {validationStatus[index]?.email && (
                <span style={{ color: "red" }}>
                  {validationStatus[index]?.email}
                </span>
              )}
            </TableCell>
            <TableCell sx={InlineStyles.binding_aggrement_type}>
            <TextField
    value={row.phone_number}
    onChange={(e) => handleInputChange(index, 'phone_number', e.target.value)}
    sx={InlineStyles.entity_divisions}
    size="small"
    placeholder='Enter Phone Number Here'
    type="tel" // Set the type to "tel"
    disabled={viewMode}
    InputProps={{
        inputProps: {
            pattern: "[0-9]{3}-[0-9]{3}-[0-9]{4}", // Example: 123-456-7890
        }
    }}
/>
              <br />
              {validationStatus[index]?.phone_number && (
                <span style={{ color: "red" }}>
                  {validationStatus[index]?.phone_number}
                </span>
              )}
            </TableCell>
            <TableCell sx={InlineStyles.binding_aggrement_type}>
              <TextField
                value={row.broker_branch_location}
                onChange={(e) =>
                  handleInputChange(
                    index,
                    "broker_branch_location",
                    e.target.value
                  )
                }
                sx={InlineStyles.entity_divisions}
                size="small"
                placeholder="Enter Details Here"
                disabled={viewMode}
              />
              <br />
              {validationStatus[index]?.broker_branch_location && (
                <span style={{ color: "red" }}>
                  {validationStatus[index]?.broker_branch_location}
                </span>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
