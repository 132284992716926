import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import StarIcon from "@mui/icons-material/Circle";
import "./dashboard.css";

export default function EmployeeAllocationTable({ data, columns }) {
  const [noData, setNoData] = useState(null)

  useEffect(() => {
    if (
      !data ||
      data.length === 0
    ) {
      setNoData(true);
      return;
    } else {
      setNoData(false);
    }
  }, [data])

  const determine_rating = (receivable, allocated) => {
    const slab = allocated / receivable;
    if (slab*100 >= 90) {
      return (
        <div>
          <StarIcon className="star green" />
          <StarIcon className="star" />
          <StarIcon className="star" />
        </div>
      );
    }
    if (slab*100 >= 75) {
      return (
        <div>
          <StarIcon className="star green" />
          <StarIcon className="star" />
          <StarIcon className="star" />
        </div>
      );
    }
    if (slab*100 >= 50) {
      return (
        <div>
          <StarIcon className="star" />
          <StarIcon className="star yellow" />
          <StarIcon className="star" />
        </div>
      );
    }
    if (slab*100 >= 30) {
      return (
        <div>
          <StarIcon className="star" />
          <StarIcon className="star yellow" />
          <StarIcon className="star" />
        </div>
      );
    } else {
      return (
        <div>
          <StarIcon className="star" />
          <StarIcon className="star" />
          <StarIcon className="star red" />
        </div>
      );
    }
  };

  return (
    <>
      {
        !noData &&
        <TableContainer style={{ height: "300px" }}>
          <Table>
            <TableHead style={{ height: 30 }}>
              <TableRow>
                {columns?.map((column) => (
                  <TableCell className="table-header" style={{ textAlign: "center",width: "max-content",fontWeight: 600, fontSize: 14, backgroundColor: "#d1d3d4"}}>{column}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody style={{ height: "280px", overflowY: "auto", textAlign: "center" }}>
              {data?.filter(row => row["user_name"] && row["user_name"].trim() !== "").map((row, index) => (
                <TableRow
                  key={index}
                  // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row" style={{ textAlign: "center" }}>
                    {row["user_name"]}
                  </TableCell>

                  <TableCell component="th" scope="row" style={{ textAlign: "center" }}>
                    {row["employee_bank_txn_count"] || 0}
                  </TableCell>

                  <TableCell component="th" scope="row" style={{ textAlign: "center" }}>
                    {row["employee_bank_txn_amount"] || 0}
                  </TableCell>

                  <TableCell component="th" scope="row" style={{ textAlign: "center" }}>
                    {row["employee_receivable_count"] || 0}
                  </TableCell>

                  <TableCell component="th" scope="row" style={{ textAlign: "center" }}>
                  {row["employee_receivable_amount"] ? row["employee_receivable_amount"].toLocaleString() : 0}
                  </TableCell>

                  <TableCell component="th" scope="row" style={{ textAlign: "center" }}>
                    {row["employee_allocated_count"] || 0}
                  </TableCell>
                  <TableCell component="th" scope="row" style={{ textAlign: "center" }}>
                  {row["employee_allocated_amount"] ? row["employee_allocated_amount"].toLocaleString() : 0}
                  </TableCell>
                  
                  <TableCell component="th" scope="row" style={{ textAlign: "center" }}>
                    {row["employee_unallocated_count"] || 0}
                  </TableCell>
                  <TableCell component="th" scope="row" style={{ textAlign: "center" }}>
                  {row["employee_unallocated_amount"] ? row["employee_unallocated_amount"].toLocaleString() : 0}
                  </TableCell>

                  <TableCell component="th" scope="row" style={{ textAlign: "center" }}>
                    {determine_rating(
                      row["employee_receivable_count"],
                      row["employee_allocated_count"]
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      }
    </>
  );
}
