import {
  DataGrid,
  GridActionsCellItem,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Broker, getBankReconciliationTable } from "../../Services/api";
import instance from "../../redux/api";
import { CommonButton } from "../common/commonButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  Grid,
  IconButton,
  Stack,
  TableContainer,
  TextField,
  Tooltip,
} from "@mui/material";
import { ViewAndEditEntity } from "./ViewAndEditEntity";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { toast } from "react-toastify";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MyComponent from "../EntityTableMaintainance/EntityDetails";
import { AddBrokerDetails } from "./AddBinding";
import { useStyles } from "../bankTransactions/bankTranscationContainerStyles";
import { CommonPaginationContainer } from "../common";

//styles

const AssignBankStatementBar = {
  display: "flex",
  justifyContent: "space-between",
  height: 32,
  fontWeight: "400",
  marginTop: 10,
};
const editEntityUpdate = {
  width: "90px",
  padding: "18px 0px",
  borderRadius: "20px",
  gap: "8px",
};

const bankTransactionAddBtn = {
  background: "#FF5A01",
  marginLeft: "5%",
  color: "white",
  borderRadius: "10px",
  padding: "5px 20px",
};

const bankStatementSearchBar = {
  width: "230px",
  height: "30px",
  padding: "2px 5px 1px 15px",
  borderRadius: "7px",
  border: "1px solid #d2cccc",
  marginTop: "5px",
};

const dialogStyle = {
  maxWidth: "90vw",
  width: "90vw",
  margin: "auto",
};
const ResetSubmit = {
  justifyContent: "flex-end",
  paddingTop: "0px",
  marginRight: "12px",
};
const addBankTransactionReset = {
  backgroundColor: "white",
  width: "90px",
  padding: "16px 0px",
  borderRadius: "20px",
  gap: "8px",
  color: "black",
  border: "1px solid black",
};

const EditPageContainerTransactions = {
  padding: "10px",
  cursor: "default",
  width: "100%",
  marginLeft: "-10px",
};
const EditPageContainerTransactionsList = {
  display: "flex",
  justifyContent: "end",
  width: "100%",
  alignItems: "center",
  marginBottom: "10px",
};

const CloseIconStyle = {
  backgroundColor: "#3B3F44",
  color: "#ffff",
  height: "16px",
  width: "16px",
  padding: "7px 8px",
  borderRadius: "32px",
};
const addBankTransactionSubmit = {
  backgroundColor: "#FF5A01",
  width: "90px",
  padding: "17px 0px",
  borderRadius: "13px",
  gap: "8px",
  color: "white",
};

export default function BrokerContainer() {
  // Selectors
  const toggle = useSelector((state) => state.toggleSideMenu);
  const { userData } = useSelector((state) => state?.user);
  const [rows, setRows] = useState([
    {
      broker_name: "",
      broker: "",
      branch: "",
      duplicate_count: "",
      soa_received_from_broker: "",
      name: "",
      email: "",
      phone_number: "",
      broker_branch_location: "",
    },
  ]);
  const [tableRows, setTableRows] = useState([]);
  const [isApiCallInprogress, setIsApiCallInprogress] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [EntityDialog, setEntityDialog] = useState(false);
  const [editRow, setEditRow] = useState("");
  const [viewMode, setViewMode] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [isCreated, setIsCreated] = useState(true);
  const [editId, setEditId] = useState("");

  // const [pageSize, setPageSize] = useState(5);
  // const [page, setPage] = useState(0);

  const [currentUpdatedFiledsInEditMode, setCurrentUpdatedFiledsInEditMode] =
    useState({});
  const [validationStatus, setValidationStatus] = useState(
    Array(rows.length).fill(true)
  );

  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchFieldParams, setSearchFieldParams] = useState({
    Broker_Name: "",
  });
  const classes = useStyles();

  //For pagination
  const [paginationCount, setPaginationCount] = useState(0);
  const [pageState, setPageState] = useState({
    isLoading: true,
    data: [],
    total: 0,
    page: 1,
    pageSize: 25,
  });

  // skip 0 means page 1
  const currentSkipNumber = () => {
    return pageState?.page === 1
      ? 0
      : pageState?.page === 0
      ? pageState?.page
      : pageState?.page - 1;
  };

  useEffect(() => {
    if (pageState?.total >= 5) {
      setPaginationCount(Math.floor(pageState.total / pageState?.pageSize));
    } else setPaginationCount(0);
  }, [pageState.total]);

  useEffect(() => {
    loadData();
  }, [pageState?.pageSize, pageState?.page]);

  const loadData = async () => {
    try {
      setPageState((old) => ({
        ...old,
        isLoading: true,
      }));
      const response = await instance.get(
        `${Broker}?skip=${currentSkipNumber()}&pageSize=${pageState?.pageSize}`
      );
      setPageState((old) => ({
        ...old,
        isLoading: false,
      }));

      if (response.status === 200) {
        setPageState((old) => ({
          ...old,
          total: response?.data?.count,
          data: response?.data ?? [],
        }));
        setPaginationCount(
          Math.floor(response?.data?.count / pageState?.pageSize)
        );
      } else {
        setPageState((old) => ({
          ...old,
          total: response?.data?.count,
          data: [],
        }));
        setPaginationCount(0);
      }
    } catch (err) {
      setPageState((old) => ({
        ...old,
        isLoading: false,
      }));
      setPaginationCount(0);
      console.error("err", err);
    }
  };

  const handleBrokerInputChange = (value) => {
    setSearchFieldParams({
      ...searchFieldParams,
      Broker_Name: value ?? "",
    });
  };

  const handleSearchAdmnBrokerList = async () => {
    try {
      let queryParams = `?skip=0&pageSize=${pageState?.pageSize}`;

      const { Broker_Name } = searchFieldParams;
      if (Broker_Name) {
        queryParams = `${queryParams}&brokerName=${Broker_Name}`;
      }

      setPageState((old) => ({
        ...old,
        isLoading: true,
      }));

      const response = await instance.get(`${Broker}${queryParams}`);

      setPageState((old) => ({
        ...old,
        isLoading: false,
      }));

      if (response.status === 200) {
        console.log("broker", response);
        setPageState((old) => ({
          ...old,
          total: response?.data?.count,
          data: response?.data ?? [],
        }));
        setPaginationCount(
          Math.floor(response?.data?.count / pageState?.pageSize)
        );
      } else {
        setPageState((old) => ({
          ...old,
          total: response?.data?.count ?? 0,
          data: [],
        }));
        setPaginationCount(0);
      }
    } catch (err) {
      setPageState((old) => ({
        ...old,
        isLoading: true,
      }));
      console.log("err", err);
    }
  };

  const isFilterBtnDisable = () => {
    let disabled = true;
    const { Broker_Name } = searchFieldParams;
    if (Broker_Name !== "") {
      disabled = false;
    }
    return disabled;
  };

  const handleClearSearchList = () => {
    loadData();
    setSearchFieldParams({
      Broker_Name: "",
    });
  };

  const Container = {
    width: toggle?.isOpen ? "calc(100vw - 305px)" : "calc(100vw - 100px)",
    display: "flex",
    flexDirection: "column",
    gap: 8,
  };

  const handleDialogClose = () => {
    setEntityDialog(false);
    setEditMode(false);
    setViewMode(false);
    if (!editMode && !viewMode) {
      setRows([
        {
          broker_name: "",
          broker: "",
          branch: "",
          duplicate_count: "",
          soa_received_from_broker: "",
          name: "",
          email: "",
          phone_number: "",
          broker_branch_location: "",
        },
      ]);
      setSelectedRows([false]);
      setValidationStatus([true]);
    }
  };

  // const handleSearch = (e) => {
  //   const value = e.target.value;
  //   setSearchText(value);
  //   let filteredData;
  //   if (value.trim() === '') {
  //     loadData();
  //     filteredData = tableRows;
  //   } else {
  //     filteredData = tableRows.filter((row) =>
  //       columns.some((column) => {
  //         const cellValue = row[column.field];
  //         if (Array.isArray(cellValue)) {
  //           // Check if the array is not null
  //           if (cellValue !== null && cellValue !== undefined) {
  //             // Check if any property within the objects in the array includes the search value
  //             return cellValue.some((item) =>
  //               Object.values(item).some(
  //                 (propValue) =>
  //                   propValue !== undefined &&
  //                   propValue !== null &&
  //                   propValue.toString().includes(value)
  //               )
  //             );
  //           }
  //         } else if (cellValue !== undefined && cellValue !== null) {
  //           // For non-array fields, continue with the existing logic
  //           return cellValue.toString().includes(value);
  //         }
  //         return false;
  //       })
  //     );
  //   }
  //   setTableRows(filteredData);
  // };

  useEffect(() => {
    if(selectAll){
      const updatedSelectedRows = [...selectedRows];
      updatedSelectedRows.fill(selectAll);
      setSelectedRows(updatedSelectedRows);
    } 
  }, [selectAll]);

  const handleCheckboxChange = (index, isChecked) => {
    const updatedSelectedRows = [...selectedRows];
    updatedSelectedRows[index] = isChecked;

    // Check the number of selected rows
    const selectedRowCount = updatedSelectedRows.filter(Boolean).length;
    setSelectedRows(updatedSelectedRows);
    setSelectAll(selectedRowCount === rows.length);
  };

  const handleActionButton = (rowData, isEditMode) => {
    const emptyRows = Array.from({ length: rows.length }, () => ({
      broker_name: "",
      broker: "",
      branch: "",
      duplicate_count: "",
      soa_received_from_broker: "",
      name: "",
      email: "",
      phone_number: "",
      broker_branch_location: "",
    }));
    if (rowData && isEditMode) {
      // Pre-fill the empty row with data for editing
      setRows([rowData]);
      setEditRow(rowData);
      setEditMode(true);
      setEditId(rowData.id);
      setIsCreated(false);
    } else if (rowData && !isEditMode) {
      setRows([rowData]);
      setViewMode(true);
    } else {
      // If no rowDataForEdit is provided, it means we are in add mode
      setRows(emptyRows);
      setIsCreated(true);
      setEntityDialog(true);
    }
    setEntityDialog(true);
    if (rowData) {
      setIsCreated(false);
    } else {
      setIsCreated(true);
    }
  };
  const columns = [
    { field: "id", headerName: "ID", flex: 0.3, headerAlign: "center" },
    {
      field: "broker_name",
      headerName: "Broker Master",
      flex: 1,
      headerAlign: "center",
    },
    // {
    //   field: "broker",
    //   headerName: "Broker",
    //   flex: 1,
    //   headerAlign: "center",
    // },
    {
      field: "branch",
      headerName: "Broker Branch",
      flex: 1,
      headerAlign: "center",
    },
    {
      field: "duplicate_count",
      headerName: "Duplicate Count",
      flex: 1,
      headerAlign: "center",
      renderCell: (params) => (
        <span>{parseInt(params?.row?.duplicate_count)}</span>
      ),
    },
    {
      field: "soa_received_from_broker",
      headerName: "Soa Received",
      flex: 1,
      headerAlign: "center",
    },
    {
      field: "name",
      headerName: "Broker Representative",
      flex: 1,
      headerAlign: "center",
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      headerAlign: "center",
    },
    {
      field: "phone_number",
      headerName: "Phone Number",
      flex: 1,
      headerAlign: "center",
    },
    {
      field: "broker_branch_location",
      headerName: "Broker Branch Location",
      flex: 1,
      headerAlign: "center",
    },
    {
      field: "updated_by",
      headerName: "Last Modified By",
      flex: 1,
      headerAlign: "center",
      valueGetter: (params) => {
        return params.row.updated_by ?? params.row.created_by
      }
    },
    ...(userData?.role !== "CC Processor" ? [{
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 0.8,
      cellClassName: "actions",
      getActions: ({ row }) => {
        let actionsList = [];

        if (UserAuthorisation?.["Edit"] === "Y") {
          actionsList.push(
            <GridActionsCellItem
              icon={
                <Tooltip title="Edit">
                  {" "}
                  <EditIcon />{" "}
                </Tooltip>
              }
              label="Edit"
              className="textPrimary"
              onClick={() => handleActionButton(row, true)}
              color="inherit"
            />
          );
        }

        // if (bankTransactions?.['Delete'] === "Y") {
        //   actionsList.push(
        //     <GridActionsCellItem
        //       icon={<Tooltip title="Delete"><DeleteIcon /> </Tooltip>}
        //       label="Delete"
        //       onClick={() => handleDeleteClick(row.id)}
        //       color="inherit"
        //     />
        //   );
        // }

        if (UserAuthorisation?.["View"] === "Y") {
          actionsList.push(
            <GridActionsCellItem
              icon={<VisibilityIcon />}
              label="view"
              className="textPrimary"
              onClick={() => handleActionButton(row, false)}
              color="inherit"
            />
          );
        }

        return actionsList;
      },
    }] : []),
  ];
  // const loadData = async () => {
  //   try {
  //     setIsApiCallInprogress(true);
  //     const resp = await instance.get(Broker);
  //     setIsApiCallInprogress(false);
  //     if (resp.status === 200) {
  //       const sortedData = resp.data.sort((a, b) => b.id - a.id);
  //       setTableRows(sortedData);
  //     } else {
  //       setTableRows([]);
  //     }
  //   } catch (err) {
  //     setIsApiCallInprogress(false);
  //     console.error("err", err);
  //   }
  // };
  const UserAuthorisation =
    userData?.user_permissions?.permissions_list["Admin Table Maintenance"];

  // useEffect(() => {
  //   loadData();
  // }, []);
  const handleInputChange = (index, colName, value) => {
    const updatedRows = [...rows];
    if (updatedRows[index]) {
      updatedRows[index][colName] = value;
      setRows(updatedRows);

      // Edit Mode
      if (editMode) {
        setCurrentUpdatedFiledsInEditMode({
          ...currentUpdatedFiledsInEditMode,
          [colName]: value,
        });
      }
    } else {
      console.error("Row at index", index, "is undefined.")
    }
  };
  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;
    const updatedSelectedRows = new Array(rows.length).fill(isChecked);

    setSelectedRows(updatedSelectedRows);
    setSelectAll(isChecked);
  };

  const handleAddRow = () => {
    setRows([
      ...rows,
      {
        broker_name: "",
        broker: "",
        branch: "",
        duplicate_count: "",
        soa_received_from_broker: "",
        name: "",
        email: "",
        phone_number: "",
        broker_branch_location: "",
      },
    ]);
    setSelectedRows([...selectedRows, false]);
  };

  useEffect(() => {
    const selectedRowCount = selectedRows.filter(Boolean).length;
    if (selectedRowCount < rows.length) {
      setSelectAll(false)
    }
  }, [rows, selectedRows])

  const handleDeleteRow = () => {
    // Ensure there is at least one row remaining
    console.log("rows", rows);
    if (rows.length <= 1 || selectAll) {
      // Throw an error or show a message indicating that at least one row should remain
      toast.error("At least One Row Should Remain!!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    const updatedRows = rows.filter((row, index) => !selectedRows[index]);
    setRows(updatedRows);

    // Clear selected rows and set Select All to false after deletion
    setSelectedRows(selectedRows.filter((ele) => !ele));
    setSelectAll(false);
  };
  const validateRow = (index) => {
    const row = rows[index];
    const fieldValidations = {};

    // Perform your validations here, for example:
    if (!row || !row.broker_name || !row.broker_name.trim() === "") {
      fieldValidations.broker_name = "This field is required";
    }
    if (!row || !row.broker || !row.broker.trim() === "") {
      fieldValidations.broker = "This field is required";
    }
    if (!row || !row.branch || !row.branch.trim() === "") {
      fieldValidations.branch = "This field is required";
    }
    if (!row || !row.duplicate_count || !row.duplicate_count.trim() === "") {
      fieldValidations.duplicate_count = "This field is required";
    }
    if (
      !row ||
      !row.soa_received_from_broker ||
      !row.soa_received_from_broker.trim() === ""
    ) {
      fieldValidations.soa_received_from_broker = "This field is required";
    }
    if (!row || !row.name || !row.name.trim() === "") {
      fieldValidations.name = "This field is required";
    }
    if (!row || !/^\S+@\S+\.\S+$/.test(row.email)) {
      fieldValidations.email = "Please enter a valid email address";
    }
    if (!row || !row.phone_number || !row.phone_number.trim() === "") {
      fieldValidations.phone_number = "This field is required";
    }
    if (
      !row ||
      !row.broker_branch_location ||
      !row.broker_branch_location.trim() === ""
    ) {
      fieldValidations.broker_branch_location = "This field is required";
    }

    // Add more validations for other fields...
    setValidationStatus((prevStatus) => {
      const newStatus = [...prevStatus];
      newStatus[index] = fieldValidations;
      return newStatus;
    });

    return Object.keys(fieldValidations).length === 0;
  };
  const handleSubmit = async () => {
    // Validate each row before submission
    const areRowsValid = rows
      .map((_, index) => validateRow(index))
      .every((value) => value);

    let editModeObj = {};
    if (!editMode) {
      // Add Mode
    } else {
      editModeObj.id = editRow?.id;
      editModeObj["updated_by"] = userData?.user_name ?? "";

      editModeObj = { ...editModeObj, ...currentUpdatedFiledsInEditMode };

      const updatedPageState = pageState?.data.map((row) => {
        if (row.id === editRow?.id) {
          // Log the `id` of the row being edited
          console.log(
            "Editing row with ID:",
            row.id,
            "User name",
            userData?.user_name
          );

          return {
            ...row,
            updated_by: userData?.user_name ?? "", // Update the `updated_by` field
          };
        } else {
          return row; // Keep the rest of the rows unchanged
        }
      });

      console.log("Updated Page State:", updatedPageState);

      setPageState((prev) => {
        console.log("Previous Page State:", prev); // Log the previous state

        return {
          ...prev,
          data: updatedPageState, // Assign the new data
        };
      });
    }
    //const areRowsValid = true;

    if (!areRowsValid) {
      // If any row is invalid, stop the submission and display an error
      toast.error("Please fill all required fields.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    try {
      let response;
      if (editMode) {
        response = await instance.patch(Broker + editId + "/", editModeObj);
        // clear preiously updated stored data
        setCurrentUpdatedFiledsInEditMode({});
      } else {
        // const flattenedRows = rows.flat();
        rows.forEach((item) => {
          item.created_by = userData?.user_name ?? "";
        });
        response = await instance.post(Broker, rows);
      }

      if (response) {
        if (editMode) {
          toast.success("Updated successfully !", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        } else {
          toast.success("Submission successful !", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        }

        // clean up
        cleanupTransactionData();
      }
    } catch (err) {
      toast.error("Submission failed. Please try again.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  const cleanupTransactionData = () => {
    handleDialogClose();
    loadData();
  };

  const handleReset = () => {
    const emptyRows = Array.from({ length: rows.length }, () => ({
      broker_name: "",
      broker: "",
      branch: "",
      duplicate_count: "",
      soa_received_from_broker: "",
      name: "",
      email: "",
      phone_number: "",
      broker_branch_location: "",
    }));
    setRows(emptyRows);
    setSelectAll(false);
    setValidationStatus(Array.from({ length: rows.length }, () => true));
    setSelectedRows(selectedRows.fill(false))
  };
  return (
    <div style={Container}>
      <div style={AssignBankStatementBar}>
        <span style={{ color: "#FF5A01", fontSize: "24px" }}>
          Broker Table
        </span>
        <div style={{ display: "flex", marginRight: "10px" }}>
          {UserAuthorisation?.["Creation"] === "Y" && (
            <CommonButton
              text="Add"
              handleClick={() => handleActionButton()}
              sx={bankTransactionAddBtn}
              hoverColor="#FF5A01"
            />
          )}
        </div>
      </div>
      {/*
      <Grid container
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          mb: "10px",
        }}
        xs={12}
      >
        <Grid item xs={3} >
          <FormLabel>Broker Name / Branch</FormLabel>
          {/* <input
            style={bankStatementSearchBar}
            type="text"
            placeholder="Search..."
            value={searchText}
          // onChange={handleSearch}
          /> * /}
          <TextField
            value={searchFieldParams.Broker_Name}
            onChange={(e) =>
              handleBrokerInputChange(e.target.value)
            }
            // sx={InlineStyle.EntityDivison}
            size="small"
            placeholder="Enter Details Here"
          />
        </Grid>
        <Grid item display={"flex"} gap={"10px"} mt={"22px"} >
          <FormLabel></FormLabel>
          <CommonButton
            text="Search"
            handleClick={handleSearchAdmnBrokerList}
            hoverColor="#EE3F00"
            disabled={pageState?.isLoading || isFilterBtnDisable()}
          />
          <FormLabel></FormLabel>
          <CommonButton
            text="Clear"
            handleClick={handleClearSearchList}
            hoverColor="#EE3F00"
            disabled={pageState?.isLoading || isFilterBtnDisable()}
            style={{ color: isFilterBtnDisable() ?? "rgba(0, 0, 0, 0.26)" }}
          />
        </Grid>
      </Grid> */}

      <div
        style={{
          height: "calc(100vh - 240px)",
          position: "relative",
          display: "flex",
          flexDirection: "row-reverse",
        }}
      >
        <DataGrid
          className={classes.pagination}
          loading={pageState?.isLoading}
          rows={pageState?.data ?? []}
          columns={columns}
          rowCount={pageState?.total}
          keepNonExistentRowsSelected
          disableSelectionOnClick
          pagination={false}
          paginationMode="server"
          getRowId={(row) => row?.id}
          page={pageState.page - 1}
          pageSize={pageState?.pageSize ?? 10}
          rowsPerPageOptions={[10, 25, 50, 100]}
          hideFooterPagination // Added on July 2 2024
          hideFooter
          // loading={isApiCallInprogress}
          // rows={tableRows.slice((page) * pageSize, (page + 1) * pageSize)}
          // pageSize={pageSize}
          // rowCount={tableRows?.length}
          // rowsPerPageOptions={[5, 10, 25, 50, 100]}
          // onPageChange={(newPage) => {
          //   setPage(newPage);
          // }}
          // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          // showCellRightBorder={true}
          // rowHeight={38}
          onPageSizeChange={(newPageSize) => {
            return setPageState((old) => {
              setPaginationCount(Math.floor(pageState.total / newPageSize));
              return { ...old, pageSize: newPageSize, page: 1 };
            });
          }}
          components={{
            Toolbar: GridToolbarContainer, // Custom toolbar for filter reset button
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                No Broker Table Maintenance
              </Stack>
            ),
          }}
          sx={{
            "& .MuiDataGrid-columnHeader[data-field='actions']": {
              minWidth: toggle?.isOpen
                ? "106px !important"
                : "123px !important",
            },
          }}
        />
        {/* // Commented on July 2 2024
        
         <Stack
          style={{
            position: "absolute",
            bottom: "8px",
          }}
        >
          <CommonPaginationContainer
            count={paginationCount + 1}
            pageState={pageState}
            setPageState={setPageState}
          />
        </Stack> */}
      </div>
      {/*
        // Commented on July 2 2024
       <MyComponent /> */}
      <Dialog
        open={EntityDialog}
        disableBackdropClick
        disableEscapeKeyDown
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        PaperProps={{ style: dialogStyle }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ cursor: "default", paddingBottom: "0px" }}
        >
          {viewMode ? "View Broker" : editMode ? "Edit Broker" : "Add Broker"}
          <IconButton
            aria-label="close"
            onClick={handleDialogClose}
            style={{ position: "absolute", right: 12, top: 12 }}
          >
            <CloseIcon style={CloseIconStyle} />
          </IconButton>
          <hr style={{ width: "100%" }} />
        </DialogTitle>
        <DialogContent
          sx={{ overflowX: "hidden", padding: "0px 24px 5px 24px" }}
        >
          <div elevation={4} style={EditPageContainerTransactions}>
            {!editMode && !viewMode && (
              <div style={EditPageContainerTransactionsList}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <CommonButton
                    // sx={addBankTransactionAdd}
                    text="Add"
                    handleClick={handleAddRow}
                    icon={<AddIcon style={{ height: "18px" }} />}
                    hoverColor="#FF5A01"
                  />
                  <CommonButton
                    sx={{
                      backgroundColor: selectAll ? "#FF5A01" : "#CCCCCC",
                      color: selectAll ? "white" : "black",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "17px 10px",
                      borderRadius: "15px",
                      "&:hover": {
                        background: selectAll ? "#FF5A01" : "#CCCCCC",
                      },
                    }}
                    text="Delete"
                    handleClick={handleDeleteRow}
                    disabled={selectedRows.filter(Boolean).length === 0}
                    icon={<DeleteIcon style={{ height: "19px" }} />}
                  />
                </div>
              </div>
            )}
            {isCreated ? (
              <TableContainer style={{ maxHeight: "300px" }}>
                <AddBrokerDetails
                  selectAll={selectAll}
                  handleInputChange={handleInputChange}
                  handleSelectAll={handleSelectAll}
                  rows={rows}
                  // handleBlur={handleBlur}
                  handleCheckboxChange={handleCheckboxChange}
                  validationStatus={validationStatus}
                  selectedRows={selectedRows}
                />
              </TableContainer>
            ) : (
              <>
                {rows?.map((row, index) => (
                  <Grid display={"flex"} flexDirection={"column"}>
                    <ViewAndEditEntity
                      row={row}
                      index={index}
                      viewMode={viewMode}
                      validationStatus={validationStatus}
                      editMode={editMode}
                      handleInputChange={handleInputChange}
                    />
                  </Grid>
                ))}
              </>
            )}
          </div>
        </DialogContent>

        <DialogActions style={ResetSubmit}>
          {viewMode ? null : (
            <>
              {isCreated && (
                <CommonButton
                  sx={addBankTransactionReset}
                  text="Reset"
                  handleClick={() => handleReset()}
                  disabled={editMode}
                />
              )}
              {editMode && (
                <CommonButton
                  sx={editEntityUpdate}
                  text="Update"
                  handleClick={handleSubmit}
                  // disabled={isDisableUpdateBtn}
                  hoverColor="#FF5A01"
                />
              )}
              {!editMode && !viewMode && (
                <CommonButton
                  sx={addBankTransactionSubmit}
                  text="Submit"
                  handleClick={handleSubmit}
                  hoverColor="#FF5A01"
                />
              )}
            </>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
