import { Routes, Route } from "react-router-dom";
import BankTransactionsConatiner from "./Components/bankTransactions/BankTransactionsConatiner";
import DataTableAllocation from "./Components/documents/bankStatementlistAllocation";
import CashAllocation from "./Components/CashAllocation/CashAllocation";
import Login from "./containers/login";
import ROUTES from "./utils/routes";
import PrivateRoutes from "./PrivateRoutes";
import ForgetPassword from "./containers/forgetPassword";
import UserList from "./containers/administration/userMaintainance/usersList";
import ManageUser from "./containers/administration/userMaintainance/manageUser";
import CashTracker from "./Components/CashTracker/CashTracker";
import EntityContainer from "./Components/EntityTableMaintainance/EntityContainer";
import LobContainer from "./Components/Lob/LobContainer";
import ScmContainer from "./Components/SCMPartners/ScmContainer";
import BindingAgreementContainer from "./Components/BindingAgreements/BindingAgreement";
import { WorkList } from "./Components/WorkList/WorkList";
import CorrectionTypeContainer from "./Components/CorrectionTableMaintaince/CorrectionTableContainer";
import AllocationStatusContainer from "./Components/AllocationStatus/AllocationStatus";
import BrokerContainer from "./Components/Broker/BrokerContainer";
import CashTransferContainer from "./Components/CashTransfer/CashTransferContainer";
import PolicyContainer from "./Components/Policy/PolicyContainer";
import CurrencyTableContainer from "./Components/CurrencyTableMaintainance/CurrencyTableContainer";
import BankExchangeRate from "./Components/BankExchangeRate/BankExchangeRate";

import PremiumTableContainer from "./Components/PremiumTable/PremiumTable";
import UploadFilesConatiner from "./Components/UploadFiles/UploadFilesContainer";

// import CrossAllocation from './Components/CashAllocation/CrossAllocation.jsx';
// import Refund from './Components/CashAllocation/Refund.jsx';

import { BankReconciliation } from "./Components/BankReconciliation/BankReconciliation";
import { LockUnlockContainer } from "./Components/AdministrationLockUnlock/LockUnlockContainer";
import { StatementUpLoad } from "./Components/StatementUpload/StatementUpLoad.jsx";
import ExceptionReport from "./Components/BDXPaymentUpdates/ExceptionReport.js";
import Dashboard from "./Components/Dashboard/Dashboard";
import TreasuryList from "./Components/BDXPaymentUpdates/TreasuryList.js";
import PremiumBDX from "./Components/BDXPaymentUpdates/PremiumBDX.js";

import DisplayReportByReportName from "./Components/Reports/DisplayReportByReportName";
import SLAContainer from "./Components/SLA/SLAContainer.js";
import ParticipatingInsurerContainer from "./Components/ParticipatingInsurer/ParticipatingInsurerContainer.js"

const MyRoutes = () => {
  return (
    <Routes>
      <Route exact path={ROUTES.HOME} element={<Login />} />
      <Route exact path={ROUTES.FORGETPASSWORD} element={<ForgetPassword />} />
      <Route element={<PrivateRoutes />}>
        <Route
          exact
          path={ROUTES.MUBST}
          element={<BankTransactionsConatiner />}
        />
        <Route exact path={ROUTES.MUUF} element={<UploadFilesConatiner />} />
        <Route exact path={ROUTES.MUSU} element={<StatementUpLoad />} />
        <Route exact path={ROUTES.MUABT} element={<DataTableAllocation />} />
        <Route exact path={ROUTES.MUCASHTRACKER} element={<CashTracker />} />
        <Route exact path={ROUTES.AUM} element={<UserList />} />
        <Route exact path={ROUTES.ALU} element={<LockUnlockContainer />} />
        <Route exact path={ROUTES.WLAI} element={<WorkList />} />
        <Route exact path={ROUTES.MUBR} element={<BankReconciliation />} />
        <Route exact path={ROUTES.AUMAU} element={<ManageUser type="add" />} />
        <Route exact path={ROUTES.AUMEU} element={<ManageUser type="edit" />} />
        <Route exact path={ROUTES.AUMVU} element={<ManageUser type="view" />} />
        <Route path="/cash-allocation" element={<CashAllocation />} />
        <Route exact path={ROUTES.AETM} element={<EntityContainer />} />
        <Route exact path={ROUTES.LOBETM} element={<LobContainer />} />
        <Route exact path={ROUTES.SCMTM} element={<ScmContainer />} />
        <Route
          exact
          path={ROUTES.BATM}
          element={<BindingAgreementContainer />}
        />
        <Route exact path={ROUTES.CTTM} element={<CorrectionTypeContainer />} />
        <Route
          exact
          path={ROUTES.ASTM}
          element={<AllocationStatusContainer />}
        />
        <Route exact path={ROUTES.BTM} element={<BrokerContainer />} />
        <Route exact path={ROUTES.CTM} element={<CashTransferContainer />} />
        <Route exact path={ROUTES.PTM} element={<PolicyContainer />} />
        <Route exact path={ROUTES.CYTM} element={<CurrencyTableContainer />} />
        <Route exact path={ROUTES.BETM} element={<BankExchangeRate />} />

        {/* // Reports start */}
        <Route path="/Reports/:report_name" element={<DisplayReportByReportName />} />
        {/* // Reports End */}

        <Route exact path={ROUTES.PMTM} element={<PremiumTableContainer />} />

        <Route exact path={ROUTES.Dashboard} element={<Dashboard />} />

        <Route
          exact
          path={ROUTES.ExceptionReport}
          element={<ExceptionReport />}
        />

        <Route exact path={ROUTES.TreasuryList} element={<TreasuryList />} />
        <Route exact path={ROUTES.PremiumBDX} element={<PremiumBDX />} />

        <Route exact path={ROUTES.SLA} element={<SLAContainer />} />
        <Route exact path={ROUTES.ParticipatingInsurer} element={<ParticipatingInsurerContainer />} />
        <Route path={"*"} element={<></>} />
        
      </Route>
    </Routes>
  );
};

export default MyRoutes;
