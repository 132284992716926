import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { EntityTableMaintainance } from "../../Services/api";
import instance from "../../redux/api";
import { CommonButton } from "../common/commonButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TableContainer,
  Tooltip,
} from "@mui/material";
import EntityDetails from "./EntityDetails";
import CloseIcon from "@mui/icons-material/Close";
import { AddEntityDetails } from "./AddEntity";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { toast } from "react-toastify";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { ViewAndEditEntity } from "./ViewAndEditEnitty";

//styles

const AssignBankStatementBar = {
  display: "flex",
  justifyContent: "space-between",
  height: 32,
  fontWeight: "400",
  marginTop: 10,
};
const editEntityUpdate = {
  width: "90px",
  padding: "18px 0px",
  borderRadius: "20px",
  gap: "8px",
};

const bankTransactionAddBtn = {
  background: "#FF5A01",
  marginLeft: "5%",
  color: "white",
  borderRadius: "10px",
  padding: "5px 20px",
};

const bankStatementSearchBar = {
  width: "180px",
  height: "30px",
  padding: "2px 5px 1px 15px",
  borderRadius: "7px",
  border: "1px solid #d2cccc",
  marginTop: "5px",
};

const ResetSubmit = {
  justifyContent: "flex-end",
  paddingTop: "0px",
  marginRight: "12px",
};
const addBankTransactionReset = {
  backgroundColor: "white",
  width: "90px",
  padding: "16px 0px",
  borderRadius: "20px",
  gap: "8px",
  color: "black",
  border: "1px solid black",
};

const EditPageContainerTransactions = {
  padding: "10px",
  cursor: "default",
  width: "100%",
  marginLeft: "-10px",
};
const EditPageContainerTransactionsList = {
  display: "flex",
  justifyContent: "end",
  width: "100%",
  alignItems: "center",
  marginBottom: "10px",
};

const CloseIconStyle = {
  backgroundColor: "#3B3F44",
  color: "#ffff",
  height: "16px",
  width: "16px",
  padding: "7px 8px",
  borderRadius: "32px",
};
const addBankTransactionSubmit = {
  backgroundColor: "#FF5A01",
  width: "90px",
  padding: "17px 0px",
  borderRadius: "13px",
  gap: "8px",
  color: "white",
};

export default function EntityContainer() {
  // Selectors
  const toggle = useSelector((state) => state.toggleSideMenu);
  const { userData } = useSelector((state) => state?.user);
  const [rows, setRows] = useState([{ entity_divisions: "", entity_name: "" }]);
  const [tableRows, setTableRows] = useState([]);
  const [isApiCallInprogress, setIsApiCallInprogress] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [EntityDialog, setEntityDialog] = useState(false);
  const [editRow, setEditRow] = useState("");
  const [viewMode, setViewMode] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [isCreated, setIsCreated] = useState(true);
  const [editId, setEditId] = useState("");
  const [pageSize, setPageSize] = useState(5);
  const [page, setPage] = useState(0);
  const [currentUpdatedFiledsInEditMode, setCurrentUpdatedFiledsInEditMode] =
    useState({});
  const [validationStatus, setValidationStatus] = useState(
    Array(rows.length).fill(true)
  );

  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([false]);

  const Container = {
    width: toggle?.isOpen ? "calc(100vw - 305px)" : "calc(100vw - 100px)",
    display: "flex",
    flexDirection: "column",
    gap: 8,
  };

  const handleDialogClose = () => {
    setEntityDialog(false);
    setEditMode(false);
    setViewMode(false);
    if (!editMode && !viewMode) {
      setRows([{ entity_divisions: "", entity_name: "" }]);
      setSelectedRows([false]);
      setValidationStatus([true]);
    }
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchText(value);
    let filteredData;
    if (value.trim() === "") {
      loadData();
      filteredData = tableRows;
    } else {
      filteredData = tableRows.filter((row) =>
        columns.some((column) => {
          const cellValue = row[column.field];
          if (Array.isArray(cellValue)) {
            // Check if the array is not null
            if (cellValue !== null && cellValue !== undefined) {
              // Check if any property within the objects in the array includes the search value
              return cellValue.some((item) =>
                Object.values(item).some(
                  (propValue) =>
                    propValue !== undefined &&
                    propValue !== null &&
                    propValue.toString().includes(value)
                )
              );
            }
          } else if (cellValue !== undefined && cellValue !== null) {
            // For non-array fields, continue with the existing logic
            return cellValue.toString().includes(value);
          }
          return false;
        })
      );
    }
    setTableRows(filteredData);
  };
  // const handleCheckboxChange = (index, isChecked) => {
  //   console.log("selected Rows123", selectedRows)
  //   const updatedRows = [...rows];
  //   updatedRows[index] = isChecked;
  //   console.log("selectted rows", updatedRows)
  //   // Check the number of selected rows
  //   const updatedSelectedRows=updatedRows.filter(Boolean)
  //   const selectedRowCount = updatedSelectedRows.filter(Boolean).length;

  //   setSelectedRows(updatedSelectedRows);
  //   setSelectAll(selectedRowCount === rows.length);
  // };

  useEffect(() => {
    if (selectAll) {
      const updatedSelectedRows = [...selectedRows];
      updatedSelectedRows.fill(selectAll);
      setSelectedRows(updatedSelectedRows);
    }
  }, [selectAll]);

  const handleCheckboxChange = (index, isChecked) => {
    const updatedSelectedRows = [...selectedRows];
    updatedSelectedRows[index] = isChecked;
    // Check the number of selected rows
    const selectedRowCount = updatedSelectedRows.filter(Boolean).length;

    setSelectedRows(updatedSelectedRows);
    setSelectAll(selectedRowCount === rows.length);
  };

  const handleActionButton = (rowData, isEditMode) => {
    const emptyRows = Array.from({ length: rows.length }, () => ({
      entity_divisions: "",
      entity_name: "",
    }));
    if (rowData && isEditMode) {
      // Pre-fill the empty row with data for editing
      setRows([rowData]);
      setEditRow(rowData);
      setEditMode(true);
      setEditId(rowData.id);
      setIsCreated(false);
    } else if (rowData && !isEditMode) {
      setRows([rowData]);
      setViewMode(true);
    } else {
      // If no rowDataForEdit is provided, it means we are in add mode
      setRows(emptyRows);
      setIsCreated(true);
      setEntityDialog(true);
    }
    setEntityDialog(true);
    if (rowData) {
      setIsCreated(false);
    } else {
      setIsCreated(true);
    }
  };

  const handleReset = () => {
    const emptyRows = Array.from({ length: rows.length }, () => ({
      entity_divisions: "",
      entity_name:""
    }));
    setRows(emptyRows);
    setSelectedRows(selectedRows.fill(false))
    setSelectAll(false);
    setValidationStatus(Array.from({ length: rows.length }, () => true));
  };

  const columns = [
    { field: "id", headerName: "ID", flex: 0.3, headerAlign: "center" },
    {
      field: "entity_divisions",
      headerName: "Entity Divisions",
      flex: 1,
      headerAlign: "center",
    },
    {
      field: "entity_name",
      headerName: "Entity Name",
      flex: 1,
      headerAlign: "center",
    },
    ...(userData?.role !== "CC Processor" ? [{
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 0.8,
      cellClassName: "actions",
      getActions: ({ row }) => {
        let actionsList = [];

        if (UserAuthorisation?.["Edit"] === "Y") {
          actionsList.push(
            <GridActionsCellItem
              icon={
                <Tooltip title="Edit">
                  {" "}
                  <EditIcon />{" "}
                </Tooltip>
              }
              label="Edit"
              className="textPrimary"
              onClick={() => handleActionButton(row, true)}
              color="inherit"
            />
          );
        }

        // if (bankTransactions?.['Delete'] === "Y") {
        //   actionsList.push(
        //     <GridActionsCellItem
        //       icon={<Tooltip title="Delete"><DeleteIcon /> </Tooltip>}
        //       label="Delete"
        //       onClick={() => handleDeleteClick(row.id)}
        //       color="inherit"
        //     />
        //   );
        // }

        if (UserAuthorisation?.["View"] === "Y") {
          actionsList.push(
            <GridActionsCellItem
              icon={<VisibilityIcon />}
              label="view"
              className="textPrimary"
              onClick={() => handleActionButton(row, false)}
              color="inherit"
            />
          );
        }

        return actionsList;
      },
    }] : []),
  ];
  const loadData = async () => {
    try {
      setIsApiCallInprogress(true);
      const resp = await instance.get(EntityTableMaintainance);
      setIsApiCallInprogress(false);
      if (resp.status === 200) {
        const sortedData = resp.data.sort((a, b) => b.id - a.id);
        setTableRows(sortedData);
      } else {
        setTableRows([]);
      }
    } catch (err) {
      setIsApiCallInprogress(false);
      console.error("err", err);
    }
  };
  const UserAuthorisation =
    userData?.user_permissions?.permissions_list["Admin Table Maintenance"];

  useEffect(() => {
    loadData();
  }, []);

  const handleInputChange = (index, colName, value) => {
    const updatedRows = [...rows];
    if (updatedRows[index]) {
      updatedRows[index][colName] = value;
      setRows(updatedRows);
      // Edit Mode
      if (editMode) {
        setCurrentUpdatedFiledsInEditMode({
          ...currentUpdatedFiledsInEditMode,
          [colName]: value,
        });
      }
    } else {
      console.error("Row at index", index, "is undefined.");
    }
  };

  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;
    const updatedSelectedRows = new Array(rows.length).fill(isChecked);

    setSelectedRows(updatedSelectedRows);
    setSelectAll(isChecked);
  };

  const handleAddRow = () => {
    // console.log("adding row", rows, selectedRows);
    setRows([...rows, { entity_divisions: "", entity_name: "" }]);
    setSelectedRows([...selectedRows, false]);
  };

  useEffect(() => {
    const selectedRowCount = selectedRows.filter(Boolean).length;
    if (selectedRowCount < rows.length) {
      setSelectAll(false);
    }
  }, [rows, selectedRows]);

  const handleDeleteRow = () => {
    // Ensure there is at least one row remaining
    if (rows.length <= 1 || selectAll) {
      // Throw an error or show a message indicating that at least one row should remain
      toast.error("At least One Row Should Remain!!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    const updatedRows = rows.filter((row, index) => !selectedRows[index]);
    setRows(updatedRows);

    // Clear selected rows and set Select All to false after deletion
    setSelectedRows(selectedRows.filter((ele) => !ele));
    setSelectAll(false);
  };
  const validateRow = (index) => {
    const row = rows[index];
    const fieldValidations = {};

    // Perform your validations here, for example:
    if (!row || !row.entity_divisions || row.entity_divisions.trim() === "") {
      fieldValidations.entity_divisions = "This field is required";
    }

    if (!row || !row.entity_name || row.entity_name.trim() === "") {
      fieldValidations.entity_name = "This field is required";
    }

    // Add more validations for other fields...
    setValidationStatus((prevStatus) => {
      const newStatus = [...prevStatus];
      newStatus[index] = fieldValidations;
      return newStatus;
    });

    return Object.keys(fieldValidations).length === 0;
  };
  const handleSubmit = async () => {
    // Validate each row before submission
    const areRowsValid = rows
      .map((_, index) => validateRow(index))
      .every((value) => value);
    let editModeObj = {};
    if (editMode) {
      editModeObj.id = editRow?.id;
      editModeObj["updated_by"] = userData?.user_name ?? "";
      editModeObj = { ...editModeObj, ...currentUpdatedFiledsInEditMode };
    }
    //const areRowsValid = true;

    if (!areRowsValid) {
      // If any row is invalid, stop the submission and display an error
      toast.error("Please fill all required fields.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    try {
      let response;
      if (editMode) {
        response = await instance.patch(
          EntityTableMaintainance + editId + "/",
          editModeObj
        );
        // clear preiously updated stored data
        setCurrentUpdatedFiledsInEditMode({});
      } else {
        // const flattenedRows = rows.flat();
        rows.forEach((item) => {
          item.created_by = userData?.user_name ?? "";
        });
        response = await instance.post(EntityTableMaintainance, rows);
      }

      if (response) {
        if (editMode) {
          toast.success("Updated successfully !", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        } else {
          toast.success("Submission successful !", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        }

        // clean up
        cleanupTransactionData();
      }
    } catch (err) {
      toast.error("Submission failed. Please try again.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  const cleanupTransactionData = () => {
    handleDialogClose();
    loadData();
  };
  return (
    <div style={Container}>
      <div style={AssignBankStatementBar}>
        <span style={{ color: "#FF5A01", fontSize: "24px" }}>
          Entity Table
        </span>
        <div style={{ display: "flex", marginRight: "10px" }}>
          {UserAuthorisation?.["Creation"] === "Y" && (
            <CommonButton
              text="Add"
              handleClick={() => handleActionButton()}
              sx={bankTransactionAddBtn}
              hoverColor="#FF5A01"
            />
          )}
        </div>
      </div>
      <div
        style={{
          height: 'calc(100vh - 230px)',
          position: "relative",
          display: "flex",
          flexDirection: "row-reverse",
        }}>
        <DataGrid
          loading={isApiCallInprogress}
          rows={tableRows}
          columns={columns}
          rowCount={tableRows?.length}
          hideFooterPagination
          hideFooter
          // pageSize={pageSize}
          // onPageChange={(newPage) => {
          //   setPage(newPage);
          // }}
          pagination={false}
          paginationMode="server"
          // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          // rowsPerPageOptions={[10, 25, 50, 100]}
          showCellRightBorder={true}
          rowHeight={38}
        />
      </div>
      {/* <EntityDetails /> */}
      <Dialog
        open={EntityDialog}
        disableBackdropClick
        disableEscapeKeyDown
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ cursor: "default", paddingBottom: "0px" }}
        >
          {viewMode ? "View Entity" : editMode ? "Edit Entity" : "Add Entity"}
          <IconButton
            aria-label="close"
            onClick={handleDialogClose}
            style={{ position: "absolute", right: 12, top: 12 }}
          >
            <CloseIcon style={CloseIconStyle} />
          </IconButton>
          <hr style={{ width: "100%" }} />
        </DialogTitle>
        <DialogContent
          sx={{ overflowX: "hidden", padding: "0px 24px 5px 24px" }}
        >
          <div elevation={4} style={EditPageContainerTransactions}>
            {!editMode && !viewMode && (
              <div style={EditPageContainerTransactionsList}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <CommonButton
                    // sx={addBankTransactionAdd}
                    text="Add"
                    handleClick={handleAddRow}
                    icon={<AddIcon style={{ height: "18px" }} />}
                    hoverColor="#FF5A01"
                  />
                  <CommonButton
                    sx={{
                      backgroundColor: selectAll ? "#FF5A01" : "#CCCCCC",
                      color: selectAll ? "white" : "black",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "17px 10px",
                      borderRadius: "15px",
                      "&:hover": {
                        background: selectAll ? "#FF5A01" : "#CCCCCC",
                      },
                    }}
                    text="Delete"
                    handleClick={handleDeleteRow}
                    disabled={selectedRows.filter(Boolean).length === 0}
                    icon={<DeleteIcon style={{ height: "19px" }} />}
                  />
                </div>
              </div>
            )}
            {isCreated ? (
              <TableContainer style={{ maxHeight: "300px" }}>
                <AddEntityDetails
                  selectAll={selectAll}
                  handleInputChange={handleInputChange}
                  handleSelectAll={handleSelectAll}
                  rows={rows}
                  // handleBlur={handleBlur}
                  handleCheckboxChange={handleCheckboxChange}
                  validationStatus={validationStatus}
                  selectedRows={selectedRows}
                />
              </TableContainer>
            ) : (
              <>
                {rows?.map((row, index) => (
                  <Grid display={"flex"} flexDirection={"column"} width={"400px"}>
                    <ViewAndEditEntity
                      row={row}
                      index={index}
                      viewMode={viewMode}
                      validationStatus={validationStatus}
                      editMode={editMode}
                      handleInputChange={handleInputChange}
                    />
                  </Grid>
                ))}
              </>
            )}
          </div>
        </DialogContent>

        <DialogActions style={ResetSubmit}>
          {viewMode ? null : (
            <>
              {isCreated && (
                <CommonButton
                  sx={addBankTransactionReset}
                  text="Reset"
                  handleClick={() => handleReset()}
                  disabled={editMode}
                />
              )}
              {editMode && (
                <CommonButton
                  sx={editEntityUpdate}
                  text="Update"
                  handleClick={handleSubmit}
                  disabled={
                    Object.keys(currentUpdatedFiledsInEditMode)?.length <= 0
                  }
                  hoverColor="#FF5A01"
                />
              )}
              {!editMode && !viewMode && (
                <CommonButton
                  sx={addBankTransactionSubmit}
                  text="Submit"
                  handleClick={handleSubmit}
                  hoverColor="#FF5A01"
                />
              )}
            </>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
